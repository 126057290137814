import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import IndiegogoImg from 'images/blog-images/indiegogo.png';
import KickStarterImg from 'images/blog-images/kickstarter.png';
import GoFundMeImg from 'images/blog-images/gofundme.png';
import CircleUpImg from 'images/blog-images/circleup.png';
import ChuffedImg from 'images/blog-images/chuffed.png';
import CausesImg from 'images/blog-images/causes.png';
import CrowdFunding from 'images/blog-images/crowdfunding.png';
import CrowdFundingFeatured from 'images/featured-image/crowdfunding-featured.png';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query Top6CrowdFunding {
      file(relativePath: { eq: "crowdfunding.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="the 6 best crowdfunding sites of 2021"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="The 6 Best Crowdfunding Sites of 2021 | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-the-6-best-crowdfunding-sites" })}
        htmlAttributes={{
          lang: 'en',
        }}
         meta={
          [
            {
              name: `description`,
              content: "",
            },
            {
              property: `og:title`,
              // content: "The 6 Best Crowdfunding Sites of 2021 | Shipkoo",
              content: intl.formatMessage({ id: "blog-post-meta-title-the-6-best-crowdfunding-sites" }),
            },
            {
              property: `og:description`,
              content: "",
            },
            {
              property: `og:image`,
              content: CrowdFundingFeatured,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="the-6-best-crowdfunding-sites-of-2021"
        title="The 6 Best Crowdfunding Sites of 2021"
        date="2021-01-21"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">
            <p>
              A crowdfunding campaign is essentially a presentation to the thousands of people located on the platform. This in turn helps them learn more about the product or service you are looking to launch. A crowdfunding campaign is a popular way to raise money for any cause – they have a broad reach and bring different people from all over the world together. Below, we take you through some of the Internet’s best crowdfunding sites.
            </p>

            <Image src={KickStarterImg} className="small-image" alt="kickstarter"/>

            <p>
              Kickstarter is one of the biggest names when it comes to crowdfunding, known for helping tech and creative entrepreneurs fund their projects before getting a loan or raising money for venture capital. The company has raised over $5 billion with more than 189,000 (as of October 2020) projects funded since its inception in 2009.
            </p>

            <p>
              Kickstarter is an all or nothing platform, which means that you don’t get your funds unless you complete your campaign. It also means that the funder’s credit card won’t be charged unless you meet your campaign goal. The fee is 5% on top of processing payment charges (3- to 5-%) per transaction. If you raise enough money, there’s a 14-day waiting period for funds.
            </p>

            <Image src={IndiegogoImg} className="small-image" alt="indiegogo"/>

            <p>
              Indiegogo does not just help innovators raise money, it offers services—from marketing and prototyping to licensing, fulfillment, and distribution—to help you bring your business to your market. Founded in 2008, Indiegogo has operations in more than 235 countries and has helped fund more than 800,000 creative ideas. It's a clear pick as best for going to market because it offers partnerships to help with all aspects of going live and launching. This includes creative services, communications, production, retail, and distribution partnerships.
            </p>

            <p>
              Unlike most crowdfunding platforms that only allow you to collect the amount of money you've raised once you meet a certain goal, with Indiegogo you have options. Indiegogo requires a fundraising minimum of $500 to run a crowdfunding campaign and you can run a campaign for a maximum of 60 days, regardless of the type of funding option you choose. If you meet your goal, Indiegogo will send you a payout within 15 business days.
            </p>

            <Image src={GoFundMeImg} className="small-image" alt="gofundme"/>

            <p>
              With GoFundMe, individuals can crowdfund for as long as they want without any set deadlines to meet their goals. This crowdfunding site collects a 2.9% processing fee and 30 cents for every donation. As it’s not an all or nothing fundraising site, you keep everything that you raise. Plus, there are zero personal campaign funding fees for those based in the United States.
            </p>

            <p>
              GoFundMe was founded in 2010 and has become one of the largest fundraising platforms around the world. Users of the platform have raised more than $9 billion from over 120 million donations. The reason we think it is a great crowdfunding site is that GoFundMe does not require you to meet any fundraising timeline or set a time limit in which you have to raise your desired financial goal. For even greater flexibility, if you are unable to reach your fundraising target, you can still receive whatever funds you are able to raise and the money will be available within a few days after the donations have been verified.
            </p>

            <Image src={CircleUpImg} className="small-image" alt="circle up"/>

            <p>
              If you’re launching a consumer brand, CircleUp is worth your attention. The platform has helped raise 260 million dollars for 196 start-up companies, and it offers both credit financing and equity capital. It is also a great space to meet retailers, experts, and entrepreneurs. On CircleUp, you’ll find accredited investors who have a net worth of at least $1 million and annual incomes of at least $200,000.
            </p>

            <p>
              The company is best for entrepreneurs who are looking to scale as opposed to develop their ideas. The selection process is fairly competitive, and typically you also need to have a revenue of at least $1 million to be listed on their site.
            </p>

            <Image src={ChuffedImg} className="small-image" alt="chuffed"/>

            <p>
              If there's a social cause you care about that you want to raise money for, then Chuffed is the platform for you because it specializes in crowdfunding for nonprofits and charities. Especially attractive is the plan where you can collect 100% of the money raised.
            </p>

            <p>
              With the Shared option, the processing payment fees are subtracted from the funds you raise, and with the Flat Fee option, both a processing fee and a 4.9% platform fee are subtracted from the funds you raise, and donors are not asked to donate to Chuffed to cover the fees. This strategy may mean you don't keep 100% of the raised money but could make donors feel better about the amount they donate, not having to worry about a fee on top of their donation.
            </p>

            <Image src={CausesImg} className="small-image"alt="causes"/>

            <p>
              Causes is the world’s largest online campaigning platform focused on social, political, and cultural issues. It brands itself as a social network for people who want to make a difference faster and more effectively. It boasts over 186 million users in 156 different countries. 
            </p>

            <p>
              Causes is a social networking platform that allows you to find people with common interests as you look through categories such as animals, human rights, and the environment. It also provides a platform for creating petitions for advocacy.
            </p>

            <Image src={CrowdFunding} alt="crowdfunding"/>

            <p>
              After you have completed your successful crowdfunding campaign, what will be the next? You will need to figure out how to fulfill and ship the orders for your backer rewards and pre-orders. <a href="https://shipkoo.com/">Shipkoo</a> is here to make this next step as pain-free as possible. Shipkoo will stay by your side. We integrate with multiple eCommerce platforms to make transitioning from crowdfunded campaigns to eCommerce shop a simple and stress-free process.
            </p>


            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="Facebook Icon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="Twitter Icon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="Linked Icon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;